import React from 'react';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import AppRouter from './Homepage';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Verdana, sans-serif;
    margin: 0;
  }
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <GlobalStyle />
    <AppRouter />
  </>
);
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Typography, Button, TextField, Card, CardContent, CardMedia, Box, CircularProgress } from '@mui/material';
import { fetchJson, updateRequest } from './common';
import { isMobile } from 'react-device-detect';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import './style.css';

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontFamily: "Digital Numbers Regular",
    fontSize: '2rem',
    color: 'white',
    backgroundColor: 'blue',
    textAlign: 'center', // Center the text
    padding: '8px 12px 16px', // Adjusted padding to move text 4px up
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    MozAppearance: 'textfield', // For Firefox
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'green',
      borderWidth: '12px', // Increased from 2px to 4px
    },
    '&:hover fieldset': {
      borderColor: 'green',
      borderWidth: '12px', // Added to maintain consistency on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green',
      borderWidth: '12px', // Added to maintain consistency when focused
    },
    '&.Mui-disabled fieldset': {
      borderColor: 'green', // Keep the same border color when disabled
      borderWidth: '12px', // Maintain consistent border width when disabled
    },
  },
});

function App({ category, name, categoryDisplayName, userId }) {
  const [product, setProduct] = useState(null);
  const [guess, setGuess] = useState('');
  const [actualPrice, setActualPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(null);
  const [url, setUrl] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const sessionPoints = useRef(0);
  const totalGames = useRef(0);
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const fetchProduct = async () => {
    setLoading(true);
    try {
      const data = await fetchJson(`/api/next?category=${category}`);
      setProduct(data);
    } catch (err) {
      console.error('Failed to fetch product. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const submitGuess = useCallback(async () => {
    setSubmitting(true);
    try {
      await updateRequest('/api/submit', { id: product.id, guess: parseInt(guess), name, userId }, data => {
        sessionPoints.current += data.points;
        totalGames.current += 1;
        setActualPrice(data.price);
        setOriginalPrice(data.originalPrice || null);
        setUrl(data.url);
        setMessage('submitted');
      });
    } catch (err) {
      console.error('Failed to submit guess. Please try again.');
    } finally {
      setSubmitting(false);
    }
  }, [product, guess]);

  useEffect(() => {
    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputRef.current && !loading && !message) {
      inputRef.current.focus();
    }
  }, [loading, message]);

  const handleNextItem = useCallback(() => {
    fetchProduct();
    setGuess('');
    setActualPrice(0);
    setOriginalPrice(null);
    setUrl(null);
    setMessage('');
  }, []);

  const getFullUrl = (url) => {
    return url.startsWith('http') ? url : `https://www.ssense.com/en-us${url}`;
  };

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      if (message) {
        handleNextItem();
      } else {
        submitGuess();
      }
    } else if (event.key === 'b' && message && url) {
      window.open(getFullUrl(url), '_blank');
    }
  }, [message, url, handleNextItem, submitGuess]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleBuy = useCallback(async () => {
    try {
      await updateRequest('/api/buy', { productId: product.id, name, userId });
    } catch (err) {
      console.error('Failed to buy product. Please try again.');
    }
  }, [product, name, userId]);

  return (
    <Container style={{ height: '100svh', overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px', position: 'relative' }}>
      <Typography onClick={() => navigate('/')} align="center" style={{ color: 'black', textTransform: 'uppercase', fontSize: '2.2rem', cursor: 'pointer' }}>
        PricedOut.lol
      </Typography>
      {categoryDisplayName && (
        <Typography align="center" variant="subtitle1">
          {categoryDisplayName}
        </Typography>
      )}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : product && (
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '20px' }}>
          <Box style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <CardMedia
              component="img"
              style={{ flex: 1, objectFit: 'contain', minHeight: 0 }}
              image={product.image}
              alt={product.name}
            />
          </Box>
          <CardContent style={{ flexShrink: 0, display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '20px' }}>
            <Typography variant="h4">{product.brand}</Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" gutterBottom style={{ whiteSpace: 'pre-line' }}>{product.name}</Typography>
              {product.salePercentage && (
                <Typography variant="h6" sx={{ color: 'red', mt: '-10px', textAlign: 'right' }}>
                  {product.salePercentage}% off
                </Typography>
              )}
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <CustomTextField
                type="number"
                value={guess}
                onChange={(e) => !message && !submitting && setGuess(e.target.value)}
                inputProps={{ min: 0, max: 10000000, readOnly: !!message || submitting }}
                fullWidth
                inputRef={inputRef}
              />
              {!message && !submitting && (
                <Button
                  variant="contained"
                  onClick={submitGuess}
                  fullWidth
                >
                  {submitting ? <CircularProgress size={24} /> : (isMobile ? "Guess" : "Guess (ENTER)")}
                </Button>
              )}
            </Box>
            {message && (
              <>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, textAlign: 'center', mb: 2 }}>
                  <Box>
                    <Typography variant="caption">Your guess</Typography>
                    <Typography variant="h4">${guess}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Price</Typography>
                    <Typography variant="h4">
                      ${actualPrice}
                      {originalPrice && (
                        <Typography
                          variant="h6"
                          component="span"
                          style={{ textDecoration: 'line-through', color: 'grey', marginLeft: '10px' }}
                        >
                          ${originalPrice}
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Your points</Typography>
                    <Typography variant="h4">{Math.abs(actualPrice - parseInt(guess))}</Typography>
                  </Box>
                </Box>
                {url && <Button variant="contained" onClick={() => { window.open(getFullUrl(url), '_blank'); handleBuy(); }} fullWidth sx={{ mb: 1 }}>{isMobile ? 'Buy It' : 'Buy It (B)'}</Button>}

                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, textAlign: 'center', mb: 2 }}>
                  <Box>
                    <Typography variant="caption">Total games</Typography>
                    <Typography variant="h4">{totalGames.current}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Average points</Typography>
                    <Typography variant="h4">{Math.round(sessionPoints.current / totalGames.current)}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">Total points</Typography>
                    <Typography variant="h4">{sessionPoints.current}</Typography>
                  </Box>
                </Box>
                <Button variant="contained" onClick={handleNextItem} fullWidth>{isMobile ? 'Next Item' : 'Next Item (ENTER)'}</Button>
              </>
            )}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default App;
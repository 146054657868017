import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, CardMedia, TextField, MenuItem, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import App from './App';
import meme from './meme.jpeg';
import { updateRequest } from './common';
import GameApp from './GameApp';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(1, 3),
}));

const categories = [
    { name: 'random', displayName: 'Random' },
    { name: 'men_accessories', displayName: "SSENSE: Men's Accessories", items: 9352 },
    { name: 'men_bags', displayName: "SSENSE: Men's Bags", items: 2545 },
    { name: 'men_clothing', displayName: "SSENSE: Men's Clothing", items: 42305 },
    { name: 'men_shoes', displayName: "SSENSE: Men's Shoes", items: 8562 },
    { name: 'women_accessories', displayName: "SSENSE: Women's Accessories", items: 7643 },
    { name: 'women_bags', displayName: "SSENSE: Women's Bags", items: 4421 },
    { name: 'women_clothing', displayName: "SSENSE: Women's Clothing", items: 42022 },
    { name: 'women_shoes', displayName: "SSENSE: Women's Shoes", items: 11649 },
    { name: 'everything_else_activity', displayName: "SSENSE: Everything Else - Activity", items: 253 },
    { name: 'everything_else_home', displayName: "SSENSE: Everything Else - Home", items: 3187 },
    { name: 'everything_else_kids', displayName: "SSENSE: Everything Else - Kids", items: 5235 },
    { name: 'everything_else_pets', displayName: "SSENSE: Everything Else - Pets", items: 104 },
    { name: 'everything_else_self_care', displayName: "SSENSE: Everything Else - Self Care", items: 2094 },
    { name: 'everything_else_technology', displayName: "SSENSE: Everything Else - Technology", items: 286 },
    { name: '56henryparis', displayName: '56 Henry Paris', items: 5 },
    { name: 'the-free-press2', displayName: 'The Free Press', items: 13 },
];

function Homepage() {
    const [mode, setMode] = useState('infinite');
    const [category, setCategory] = useState('random');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        let selectedCategory = category;

        if (category === 'random') {
            const randomIndex = Math.floor(Math.random() * (categories.length - 1)) + 1;
            selectedCategory = categories[randomIndex].name;
        }
        let selectedCategoryDisplayName = categories.find(cat => cat.name === selectedCategory)?.displayName;

        const name = localStorage.getItem('name') || '';

        if (mode === 'infinite') {
            navigate(`/category/${selectedCategory}`, { state: { categoryDisplayName: selectedCategoryDisplayName } });
        } else {
            try {
                await updateRequest('/api/create_game',
                    { name, category: selectedCategory, userId: localStorage.getItem('userId') },
                    data => {
                        navigate(`/game/${data.gameId}`, { state: { products: data.products, categoryDisplayName: selectedCategoryDisplayName } });
                    }
                );
            } catch (err) {
                setError('Failed to create game. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', py: 4 }}>
            <Typography variant="h5" sx={{ fontSize: '2.2rem' }} gutterBottom>
                PRICEDOUT.LOL
            </Typography>
            <Typography variant="body1" paragraph>
                Guess the price and get 1 point for every dollar away from the true price. Just like in golf, the goal is to get the lowest score.
            </Typography>
            <Typography variant="body1" paragraph>
                If the item costs $500 and you guess $400 or $600, you'll get 100 points. If you guess $495 or $505, you'll get 5 points.
            </Typography>
            <Box sx={{ my: 4 }}>
                <CardMedia
                    component="img"
                    image={meme}
                    alt="Meme"
                    sx={{ maxWidth: '100%', height: 'auto', margin: 'auto' }}
                />
            </Box>
            <Typography variant="body1" paragraph>
                If you like what you see, there's a Buy link at the bottom after you guess.
            </Typography>
            <Typography variant="h6" component="h2" gutterBottom>
                Choose a category to get started:
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                <TextField
                    label="Your Name (optional)"
                    variant="outlined"
                    fullWidth
                    value={localStorage.getItem('name') || ''}
                    onChange={(e) => localStorage.setItem('name', e.target.value)}
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Mode"
                    variant="outlined"
                    select
                    fullWidth
                    value={mode}
                    onChange={(e) => setMode(e.target.value)}
                    sx={{ mb: 2 }}
                >
                    <MenuItem value="infinite">Infinite</MenuItem>
                    <MenuItem value="game">10 Questions</MenuItem>
                </TextField>
                <TextField
                    label="Category"
                    variant="outlined"
                    select
                    fullWidth
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    sx={{ mb: 2 }}
                >
                    {categories.map((cat) => (
                        <MenuItem key={cat.name} value={cat.name}>
                            {cat.displayName}{cat.items ? ` (${cat.items} items)` : ''}
                        </MenuItem>
                    ))}
                </TextField>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <StyledButton type="submit" variant="contained" color="primary">
                        Start Game
                    </StyledButton>
                )}
                {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
            </Box>
            <Typography variant="body2" sx={{ mt: 4 }}>
                Last updated: <i>2024-10-28</i>
            </Typography>
            <Typography variant="body2" sx={{ mt: 4 }}>
                Created by Max Chiswick & Simon Baars
            </Typography>
        </Container>
    );
}

function CategoryPage() {
    const { category } = useParams();
    const { state } = useLocation();

    return <App category={category} name={localStorage.getItem('name')} categoryDisplayName={state?.categoryDisplayName} userId={localStorage.getItem('userId')} />;
}

function GameAppPage() {
    const { gameId } = useParams();
    const { state } = useLocation();

    if (!state?.products && !localStorage.getItem(`game_${gameId}_state`)) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Alert severity="error">This game is no longer available.</Alert>
            </Box>
        );
    }

    return <GameApp products={state?.products} gameId={gameId} name={localStorage.getItem('name')} categoryDisplayName={state?.categoryDisplayName} userId={localStorage.getItem('userId')} />;
}

function AppRouter() {
    useEffect(() => {
        if (!localStorage.getItem('userId')) {
            localStorage.setItem('userId', uuidv4());
        }
    }, []);

    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<Homepage />} />
                <Route path="/category/:category" element={<CategoryPage />} />
                <Route path="/game/:gameId" element={<GameAppPage />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;
